import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
import { AppPreloadingStrategy, AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPageModule } from './pages/main/main.page.module';
import { ConfirmPopoverModule } from './popovers/confirm/confirm.popover.module';
import { LoadPopoverModule } from './popovers/load/load.popover.module';
import { LoadingModule, NotifyComponent, ReadonlyOverlayComponent } from './shared/components';
import { SideMenuComponent } from './shared/components/side-menu/side-menu.component';
import { Notification, RuntimeLayoutNotifyType } from './shared/models';
import { AppService, NotificationService } from './shared/services';
import { SharedModule } from './shared/shared.module';
import { LogUtils } from './shared/utils';
import { mdTransitionAnimation } from '@ionic/core/dist/collection/utils/transition/md.transition.js';
const { name, version } = require('../../package.json');


Sentry.init({
  dsn: environment.sentryDNS,
  environment: environment.name,
  normalizeDepth: 5,
  release: name + '@' + version,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  private appService: AppService;
  private notificationService: NotificationService;

  constructor(
    private injector: Injector,
  ) {}

  handleError(error) {
    this.appService = this.injector.get(AppService);
    this.notificationService = this.injector.get(NotificationService);

    LogUtils.error(error);
    this.notificationService.showNotification(new Notification({
      title: 'Application Critical',
      text: 'An unknown error has occured.',
      type: RuntimeLayoutNotifyType.CriticalServerError,
    }));

    Sentry.configureScope((scope) => {
      scope.setExtra('deviceEnrollment', this.appService.getDeviceEnrollment());
      scope.setExtra('layoutSnapshot', this.appService.getLayoutSnapshot());
      scope.setExtra('log', LogUtils.getLogArray());

      Sentry.captureException(error?.originalError || error);
    });
  }
}

/**
 * @hidden
 * This class overrides the default Angular gesture config.
 */
@Injectable()
export class IonicGestureConfig extends HammerGestureConfig {
  overrides = {
    tap: { threshold: 70, posTreshold: 10, time: 2500 }, // default 2, 10
    pan: { threshold: 500, posTreshold: 2 } // default 2, 10
  };

  buildHammer(element: HTMLElement) {
    const mc = new (window as any).Hammer(element);

    for (const eventName in this.overrides) {
      if (eventName) {
        mc.get(eventName).set(this.overrides[eventName]);
      }
    }
    return mc;
  }
}



@NgModule({
    declarations: [
        AppComponent,
        NotifyComponent,
        ReadonlyOverlayComponent,
        SideMenuComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        ConfirmPopoverModule,
        MainPageModule,
        HttpClientModule,
        IonicModule.forRoot({
            navAnimation: mdTransitionAnimation,
            scrollAssist: false,
            scrollPadding: false,
        }),
        IonicStorageModule.forRoot(),
        LoadingModule,
        SharedModule.forRoot(),
        LoadPopoverModule,
    ],
    providers: [
        AppPreloadingStrategy,
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
