import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Platform } from '@ionic/angular';
import { DictString, Notification, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutNotifyType, RuntimeLayoutValue, RuntimeLayoutValueType, Settings, SolutionDeviceControlScannerEnabledFlagType } from 'src/app/shared/models';
import { RuntimeLayoutNativeKeyboard } from 'src/app/shared/models/runtime-layout/runtime-layout-native-keyboard.enum';
import { KeyboardType } from '../../../models/keyboard-type.enum';
import { BrowserUtils, LogUtils } from '../../../utils';
import { BARCODE_TYPES } from '../../barcode-scanner/barcode-scanner-livestream/barcode-types';
import { KeyboardService } from '../../keyboard';
import { ControlBaseComponent } from '../base/control-base.component';

export enum TextboxType {
  Normal = 0,
  Password = 1,
  Multiline = 2,
  Date = 3,
  Time = 4,
  DateTime = 5,
}

@Component({
  selector: 'lc-control-input1',
  templateUrl: 'control-input1.component.html',
  styleUrls: ['./control-input1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlInput1Component extends ControlBaseComponent implements OnInit, AfterViewInit {

  readonly barcodeTypes = BARCODE_TYPES;

  @ViewChild('input', { static: false }) inputRef: ElementRef;
  @ViewChild('overlayValue', { static: false }) overlayValueRef: ElementRef;
  @ViewChild('placeholder', { static: false }) placeholderRef: ElementRef;

  @Input() disabled: boolean;
  @Input() startNumeric: boolean;
  @Input() uppercase: boolean;

  bufferValues: string[];
  fixFontSizeTimeout: any;
  inputAutoCompleteBustingId: string;
  keyboardType: KeyboardType;
  maxFontSize: number = 40; // 2.5rem;
  minFontSize: number = 12;
  rawValueMask: string;
  readonly: boolean;
  settings: Settings;
  textboxType: TextboxType;
  value: string;
  valueMask: string;

  valueUpToCursor: string;
  multilineScrollTop: number = 0;
  multilineScrollLeft: number = 0;
  touchStartTick: number;

  constructor(
    private cdr: ChangeDetectorRef,
    private clipboard: Clipboard,
    private device: Device,
    injector: Injector,
    private keyboard: Keyboard,
    private keyboardService: KeyboardService,
    private platform: Platform,
  ) {
    super(injector);

    this.inputAutoCompleteBustingId = Date.now().toString();

    this.subscriptions.push(
      this.notificationService.getBlockingState()
      .subscribe((blocked: boolean) => {
        if (blocked) {
          this.disableScannerPlugins();
        } else {
          this.appService.refreshScannerPlugins(true);
        }
      }),

      this.appService.listenToFocusActiveControl()
      .subscribe(() => {
        // this is mostly used by the Load Popover
        // when load popover is dismissed we want to force focus on the input
        this.initAndFocus();
      }),
    )
  }

  ngOnInit() {
    this.settings = this.localSettingsService.get();
    this.readonly = this.settings.keyboard === RuntimeLayoutNativeKeyboard.LogicCenter;

    if (this.staticControl) {
      this.value = this.staticControl.defaultTextBoxValue || '';
      this.textboxType = this.staticControl.textboxType || 0;
    } else if (this.layoutControl) {
      const defaultTextBoxValue = this.layoutControl.parseRV('DefaultTextBoxValue');
      this.value = defaultTextBoxValue != null ? defaultTextBoxValue : this.layoutControl.parseRV('TextboxText', '');
      this.textboxType = parseInt(this.layoutControl.parseRV('TextboxType', '0'));

      this.rawValueMask = this.layoutControl.parseRV('TextBoxValueMask', '');
      this.valueMask = '';
      for (let i = 0; i < this.rawValueMask.length; i++) {
        if (this.rawValueMask[i] === '\\') {
          i++;
          continue;
        } else {
          this.valueMask += this.rawValueMask[i].toUpperCase() === 'A' ? 'C' : this.rawValueMask[i] === '0' ? 'D' : this.rawValueMask[i] === '*' ? 'W' : this.rawValueMask[i];
        }
      }
    } else {
      this.value = '';
    }
  }

  ngAfterViewInit() {
    this.initAndFocus();
  }

  initAndFocus() {
    const keyboardAutoEnable = this.staticControl?.keyboardAutoEnable
    ? true
    : this.layoutControl?.renderValues && this.layoutControl.parseRV('KeyboardAutoEnable')
    ? true
    : false;
    this.keyboardType = this.getKeyboardType();
    this.cdr.markForCheck();

    setTimeout(() => {
      if (!this.inputRef) return;

      if (!this.readonly) {
        this.keyboardService.hide();
        if (keyboardAutoEnable) {
          if (!this.rawValueMask) this.inputRef.nativeElement.select();
          this.inputRef.nativeElement.focus();
          if (this.platform.is('android')) this.keyboard.show();
        }
      } else {
        if (!this.rawValueMask) this.inputRef.nativeElement.select();
        else this.inputRef.nativeElement.focus();

        if (keyboardAutoEnable) {
          this.keyboardService.show(
            this.keyboardType,
            this.layout?.useLanguageKeyboard,
            this.inputRef.nativeElement,
            this.onSoftwareKeyPress.bind(this),
            this.uppercase,
            this.startNumeric,
          );
        } else {
          this.keyboardService.setActiveElement(this.inputRef.nativeElement, this.onSoftwareKeyPress.bind(this));
        }
      }

      setTimeout(() => {
        this.valueChanged();
      }, 10);
    }, 100);
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const context: any = {};
    if (this.layoutControl?.parseRV('InputBuffering')) {
      this.addToBuffer();

      context['BufferValues'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(this.bufferValues || []),
        valueTypeId: RuntimeLayoutValueType.String
      });

      this.value = '';
      this.valueChanged();
    } else {
      context['TextBox'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(this.scanValue || this.value || this.inputRef?.nativeElement?.value || ''),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    if (this.layoutControl?.parseRV('EventGps')) {
      context['EventGps'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    this.scanValue = '';

    return context;
  }

  getKeyboardType(): KeyboardType {
    let keyboardType = KeyboardType.None;

    if (this.rawValueMask) {
      const keyboardType = this.getKeyboardTypeFromMaskChar();
      if (keyboardType) return keyboardType;
    }

    if (this.staticControl?.keyboardType) {
      keyboardType = this.staticControl.keyboardType;
    } else if (this.layoutControl?.renderValues?.KeyboardType) {
      keyboardType = this.layoutControl.parseRV('KeyboardType');
      if (keyboardType === KeyboardType.AlphaNumeric && this.textboxType === TextboxType.Multiline) keyboardType = KeyboardType.AlphaNumericWithEnter;
    }

    return keyboardType;
  }

  getKeyboardTypeFromMaskChar(): KeyboardType | undefined {
    if (!this.rawValueMask) return undefined;

    let nextMaskChar;
    for(let i = (this.value || '').length; i < this.rawValueMask?.length; i++) {
      nextMaskChar = this.rawValueMask[i]
      if (nextMaskChar === 'A') {
        return KeyboardType.Alpha;
      } else if (nextMaskChar === '0') {
        return KeyboardType.Numeric;
      } else if (nextMaskChar === '*') {
        return KeyboardType.AlphaNumeric;
      }
    }

    return undefined;
  }

  onClick(event: Event): void {
    this.vibrationService.vibrate(true);

    if (this.inputRef?.nativeElement === document.activeElement) {
      this.keyboardType = this.getKeyboardType();

      if (
        (event as any).offsetX <= (this.hasBarcodeEnabled() || this.hasNfcEnabled() ? 70 : 30)
      ) {
        // click on the left side of the input: TOGGLE SOFT SCAN (OR nfc error if running on web)
        event.stopPropagation();

        this.inputRef.nativeElement.blur();
        this.keyboardService.hide();

        if (this.hasNfcEnabled() && !this.hasBarcodeEnabled()) {
          if (!BrowserUtils.isDeviceApp()) {
            this.notificationService.showNotification(new Notification({
              title: this.translateService.instant('Notification'),
              text: this.translateService.instant('NFC is only available on device apps.'),
              type: RuntimeLayoutNotifyType.Alert
            }));
          } else {
            this.notificationService.showNotification(new Notification({
              title: this.translateService.instant('Notification'),
              text: this.translateService.instant('Tap your phone on a NFC tag to scan it.'),
              type: RuntimeLayoutNotifyType.Confirmation
            }));
          }
        } else {
          this.toggleSoftScan();
        }
      } else if (
        (event as any).offsetX >= (this.inputRef.nativeElement.clientWidth - 45) &&
        this.keyboardType !== KeyboardType.None &&
        !this.keyboardService.isVisible()
      ) {
        // click on the right side of the input: HTML KEYBOARD
        if (!this.readonly) return;

        if (!this.rawValueMask) this.inputRef.nativeElement.select();
        else this.inputRef.nativeElement.focus();

        this.keyboardService.show(
          this.keyboardType,
          this.layout?.useLanguageKeyboard,
          this.inputRef.nativeElement,
          this.onSoftwareKeyPress.bind(this),
          this.uppercase,
          this.startNumeric,
        );

        this.updateValueUpToCursor(true);
      } else if (this.keyboardType !== KeyboardType.None) {
        // click on the center of the input: EITHER ACCEPT KEYBOARD INPUT (BROWSER) OR SHOW HTML KEYBOARD (MOBILE)
        if (!this.readonly) return;

        // Show HTML Keyboard (MOBILE) if it's not already showing
        if (!this.keyboardService.isVisible()) {
          if (!this.rawValueMask) this.inputRef.nativeElement.select();
          else this.inputRef.nativeElement.focus();

          this.keyboardService.show(
            this.keyboardType,
            this.layout?.useLanguageKeyboard,
            this.inputRef.nativeElement,
            this.onSoftwareKeyPress.bind(this),
            this.uppercase,
            this.startNumeric,
          );
        }

        this.updateValueUpToCursor(true);
      }
    } else {
      this.keyboardService.hide();
    }
  }

  onSoftwareKeyPress(key: string): void {
    if (!this.inputRef) return;

    this.value = this.inputRef.nativeElement.value || ''; // the keyboard doesn't update the value field...

    if (key === 'Enter' && this.isScannerEmulator) {
      // this only happens really on the top scanner drawer control
      this.emulateScan();
    } else if (key === 'Enter' && this.textboxType === TextboxType.Multiline) {
      const selectionStart = this.inputRef.nativeElement.selectionStart;
      const selectionEnd = this.inputRef.nativeElement.selectionEnd;
      if (selectionStart !== selectionEnd) { // overwrite selection. drag selecting on mobile doesn't update the this.currentSelectionStart/End
        this.value = this.value.substring(0, selectionStart) + '\n' + this.value.substring(selectionEnd);
        this.keyboardService.currentSelectionStart = selectionStart + 1;
      } else {
        this.value = this.value.substring(0, this.keyboardService.currentSelectionStart) + '\n' + this.value.substring(this.keyboardService.currentSelectionStart);
        this.keyboardService.currentSelectionStart = this.keyboardService.currentSelectionStart + 1;
      }

      this.keyboardService.currentSelectionStart = Math.max(0, this.keyboardService.currentSelectionStart); // don't let it be < 0
      this.keyboardService.currentSelectionEnd = this.keyboardService.currentSelectionStart;

      this.inputRef.nativeElement.value = this.value || '';
      this.valueChanged();
    } else {
      this.valueChanged();

      if (!this.rawValueMask) return;

      this.keyboardType = this.getKeyboardTypeFromMaskChar();
      if (!this.readonly) return;
      if (this.keyboardType) {
        this.keyboardService.show(
          this.keyboardType,
          this.layout?.useLanguageKeyboard,
          undefined, // keep the same activeElement...
          this.onSoftwareKeyPress.bind(this),
          this.uppercase,
          this.startNumeric,
        );
      }
      if (key === 'Backspace') return;

      let nextMaskChar;
      for(let i = (this.value || '').length; i < this.rawValueMask?.length; i++) {
        nextMaskChar = this.rawValueMask[i]
        if (['A', '0', '*'].indexOf(nextMaskChar) >= 0) break;

        this.keyboardService.currentSelectionStart = this.keyboardService.currentSelectionStart + 1;
      }
    }
  }

  onTouchStart(ev: Event) {
    this.touchStartTick = performance.now();
  }

  onTouchMove(ev: Event) {
    this.touchStartTick = undefined;
  }

  onTouchEnd(ev: Event) {
    if (!this.touchStartTick) return;
    if (!this.readonly) return;

    if (performance.now() - this.touchStartTick > 2 * 1000) {
      ev.stopPropagation();

      if (BrowserUtils.isDeviceApp()) {
        this.clipboard.paste()
        .then(text => {
          this.value = text || '';
          this.cdr.markForCheck();
        })
        .catch(err => {
          LogUtils.error('Failed to read clipboard contents: ', err);
        });
      } else {
        navigator.clipboard.readText()
        .then(text => {
          this.value = text || '';
          this.cdr.markForCheck();
        })
        .catch(err => {
          LogUtils.error('Failed to read clipboard contents: ', err);
        });
      }
    }

    this.touchStartTick = undefined;
  }

  @HostListener('keydown', ['$event'])
  onPhysicalKeyDown(event: KeyboardEvent): void {
    if (this.disabled) return;
    if (!this.readonly) return;

    if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      if (BrowserUtils.isDeviceApp()) {
        this.clipboard.paste()
        .then(text => {
          this.value = text || '';
          this.cdr.markForCheck();
        })
        .catch(err => {
          LogUtils.error('Failed to read clipboard contents: ', err);
        });
      } else {
        navigator.clipboard.readText()
        .then(text => {
          this.value = text || '';
          this.cdr.markForCheck();
        })
        .catch(err => {
          LogUtils.error('Failed to read clipboard contents: ', err);
        });
      }
    }
  }

  @HostListener('keyup', ['$event'])
  onPhysicalKeyUp(event: KeyboardEvent): void {
    // LogUtils.log('KeyUp: ', {
    //   altKey: event.altKey,
    //   code: event.code,
    //   ctrlKey: event.ctrlKey,
    //   isComposing: event.isComposing,
    //   key: event.key,
    //   location: event.location,
    //   metaKey: event.metaKey,
    //   repeat: event.repeat,
    //   shiftKey: event.shiftKey,

    //   charCode: event.charCode,
    //   keyCode: event.keyCode,
    //   which: event.which,
    // });

    if (this.disabled) return;

    if (
      event.key === 'Unidentified' &&
      (this.device?.manufacturer || '').toLowerCase().indexOf('samsung') >= 0 &&
      (this.device?.model || '').toUpperCase().indexOf('SM-G525F') >= 0
    ) {
      this.toggleSoftScan();
    } else if (event.key === 'Enter' && this.isScannerEmulator) {
      // this only happens really on the top scanner drawer control
      this.emulateScan();
    } else if (
      event.key === 'Enter' &&
      this.textboxType !== TextboxType.Multiline &&
      this.isActiveControl() &&
      !this.scannerService.ignoreEnterKeyInPrimaryLayoutControl
    ) {
      this.triggerEvent.emit({
        platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton,
      });
      this.vibrationService.vibrate();
    } else if (
      this.readonly &&
      (['Backspace', 'Enter'].indexOf(event.key) >= 0 || event.key.length === 1)
    ) {
      // if on mobile and we receive a keyPress it's probably from a physical keyboard so
      // pass it on to the keyboardService to handle and update the input
      this.keyboardService.processKey(event.key);
    } else if (this.inputRef) { // probably running on a desktop browser with physical keyboard
      this.value = this.inputRef.nativeElement.value || ''; // the keyboard doesn't update the value field...
      this.keyboardService.currentSelectionStart = this.inputRef.nativeElement.selectionStart;
      this.keyboardService.currentSelectionEnd = this.inputRef.nativeElement.selectionEnd;
      this.valueChanged();
    }
  }

  private emulateScan() {
    this.scanValue = this.value;
    this.scannerService.emitScan({
      source: 'SCANNER EMULATION',
      value: this.scanValue,
      valueType: undefined,
    });

    this.triggerEvent.emit(null);

    setTimeout(() => {
      this.value = '';
      this.valueChanged();
    }, 10);
  }

  inputHasFocus() {
    return document.activeElement === this.inputRef?.nativeElement || this.keyboardService.isVisible();
  }

  valueChanged() {
    if (!this.inputRef?.nativeElement) return;

    this.inputRef.nativeElement.value = this.value || '';

    if (this.textboxType !== TextboxType.Multiline) this.fixFontSize();
    else if (this.textboxType === TextboxType.Multiline) this.multilineScrollTop = this.inputRef.nativeElement.scrollTop;

    this.updateValueUpToCursor();
  }

  private updateValueUpToCursor(useElementSelectionStart?: boolean) {
    this.valueUpToCursor = this.inputRef.nativeElement.value;

    if (useElementSelectionStart) this.keyboardService.updateInternalSelectionRange();

    this.valueUpToCursor = this.valueUpToCursor.substring(0, this.keyboardService.currentSelectionStart);

    this.cdr.markForCheck();
  }

  hasBarcodeEnabled() {
    return this.control.scannerEnabledType &&
      (
        this.control.scannerEnabledType === SolutionDeviceControlScannerEnabledFlagType.LegacySimpleBarcode ||
        this.control.scannerEnabledType === SolutionDeviceControlScannerEnabledFlagType.LegacyAdvancedBarcode ||
        (this.control.scannerEnabledType & SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner) === SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner ||
        (this.control.scannerEnabledType & SolutionDeviceControlScannerEnabledFlagType.BluetoothScanner) === SolutionDeviceControlScannerEnabledFlagType.BluetoothScanner
      );
  }

  hasNfcEnabled() {
    return this.control.scannerEnabledType &&
      (this.control.scannerEnabledType & SolutionDeviceControlScannerEnabledFlagType.BuiltInNFC) === SolutionDeviceControlScannerEnabledFlagType.BuiltInNFC;
  }

  addToBuffer() {
    this.bufferValues = this.bufferValues || [];
    this.appService.inputBufferingSetValues[this.layoutControl?.parseRV('InputBufferingItemUniqueSetId', '')] = this.appService.inputBufferingSetValues[this.layoutControl?.parseRV('InputBufferingItemUniqueSetId', '')] || [];

    const hasUniqueSetId = !!this.layoutControl?.parseRV('InputBufferingItemUniqueSetId', '');
    const value = this.scanValue || this.value || this.inputRef?.nativeElement?.value || '';
    if (!value) {
      LogUtils.log('InputBuffering: ignoring - no value.');
    } else if (this.layoutControl.parseRV('InputBufferingItemRegEx') && !(new RegExp(this.layoutControl.parseRV('InputBufferingItemRegEx'))).test(value)) {
      LogUtils.log('InputBuffering: ignoring - value doesn\'t pass regex.');
    } else if (hasUniqueSetId && !this.layoutControl.parseRV('InputBufferingItemAllowSame') && this.appService.inputBufferingSetValues[this.layoutControl?.parseRV('InputBufferingItemUniqueSetId', '')].indexOf(value) >= 0) {
      LogUtils.log('InputBuffering: ignoring - value already read for this uniqueSetId.');
    } else if (!hasUniqueSetId && !this.layoutControl.parseRV('InputBufferingItemAllowSame') && this.bufferValues.indexOf(value) >= 0) {
      LogUtils.log('InputBuffering: ignoring - value already read on the current control buffer.');
    } else {
      this.bufferValues.push(value);
      this.appService.inputBufferingSetValues[this.layoutControl?.parseRV('InputBufferingItemUniqueSetId', '')].push(value);
    }
  }

  private fixFontSize() {
    if (this.fixFontSizeTimeout) clearTimeout(this.fixFontSizeTimeout);
    this.fixFontSizeTimeout = setTimeout(() => {
      if (this.isOverflowed()){
        this.decreaseSize();
      } else if ((this.value || '').length > 8) {
        this.maximizeSize();
      }

      setTimeout(() => {
        this.multilineScrollLeft = this.inputRef.nativeElement.scrollLeft;
        this.cdr.markForCheck();
      }, 100);
    }, 1000);
  }

  private decreaseSize() {
    if (!this.inputRef) return;

    const inputEl = this.inputRef.nativeElement;
    const win = document.defaultView || window;
    let fontSize = parseInt(win?.getComputedStyle(inputEl, null)?.fontSize);
    if (!isNaN(fontSize) && this.isOverflowed() && fontSize > this.minFontSize) {
      this.decreaseSizeLoop(fontSize);
    }
  }

  private decreaseSizeLoop(fontSize: number, counter: number = 0) {
    if (counter >= 10) return;

    fontSize = fontSize - 1;
    let fontSizeInPx = fontSize.toString() + 'px';

    const inputEl = this.inputRef.nativeElement;
    const placeholderEl = this.placeholderRef?.nativeElement;
    const hiddenValueEl = this.overlayValueRef?.nativeElement;
    inputEl.style.fontSize = fontSizeInPx;
    if (placeholderEl) placeholderEl.style.fontSize = fontSizeInPx;
    if (hiddenValueEl) hiddenValueEl.style.fontSize = fontSizeInPx;

    this.cdr.markForCheck();

    setTimeout(() => {
      if (!isNaN(fontSize) && this.isOverflowed() && fontSize > this.minFontSize) {
        this.decreaseSizeLoop(fontSize, ++counter);
      }
    }, 10);
  }

  private maximizeSize() {
    if (!this.inputRef) return;

    const inputEl = this.inputRef.nativeElement;
    const win = document.defaultView || window;
    let fontSize = parseInt(win?.getComputedStyle(inputEl, null)?.fontSize);
    if (!isNaN(fontSize) && !this.isOverflowed() && fontSize < this.maxFontSize) {
      this.maximizeSizeLoop(fontSize);
    }
  }

  private maximizeSizeLoop(fontSize: number, counter: number = 0) {
    if (counter >= 10) return;

    fontSize = fontSize + 1;
    let fontSizeInPx = fontSize.toString() + 'px';

    const inputEl = this.inputRef.nativeElement;
    const placeholderEl = this.placeholderRef?.nativeElement;
    const hiddenValueEl = this.overlayValueRef?.nativeElement;
    inputEl.style.fontSize = fontSizeInPx;
    if (placeholderEl) placeholderEl.style.fontSize = fontSizeInPx;
    if (hiddenValueEl) hiddenValueEl.style.fontSize = fontSizeInPx;

    this.cdr.markForCheck();

    setTimeout(() => {
      // if this loop increases beyond the width, decrease again. hacky...
      if (this.isOverflowed()) {
        this.decreaseSize();
      } else if (!isNaN(fontSize) && !this.isOverflowed() && fontSize < this.maxFontSize) {
        this.maximizeSizeLoop(fontSize, ++counter);
      }
    }, 10);
  }

  private isOverflowed() {
    if (!this.inputRef) return false;

    const inputEl = this.inputRef.nativeElement;
    // HACK for ios...for some reason the scrollWidth on iOS doesn't include the padding and it does on android / browser
    const scrollWidth = BrowserUtils.isDeviceApp() && this.platform.is('ios')
    ? inputEl.scrollWidth + (this.hasBarcodeEnabled() || this.hasNfcEnabled() ? 80 : 50) + (this.readonly ? 50 : 5)
    : inputEl.scrollWidth;
    return inputEl.clientWidth && (scrollWidth > inputEl.clientWidth + 1);
  };

}

