import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AndroidFullScreen, AndroidSystemUiFlags } from '@ionic-native/android-full-screen/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController, Platform, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { BehaviorSubject, EMPTY, from, Observable, Observer, of, Subject, Subscription, throwError, zip } from 'rxjs';
import { catchError, delay, map, mergeMap } from 'rxjs/operators';
import { ConfirmPopover } from 'src/app/popovers/confirm/confirm.popover';
import { environment } from 'src/environments/environment';
import { oem } from 'src/environments/oem';
import { KeyboardService } from '../../components';
import { AuthResponse, CriticalErrorMessage, DeviceEnrollment, DeviceRunStatus, DictNumber, DictString, HostRestartResponse, LayoutChangeEnrollmentMessage, LayoutCoreMessage, LayoutMessageResult, LayoutMessageType, Notification, RuntimeLayoutLoad, RuntimeLayoutNotifyType, RuntimeLayoutScreenFlowSolutionType, RuntimeLayoutSetting, RuntimeLayoutSettingGroup, RuntimeLayoutSnapshot, SolutionChangeMessage, SolutionInfoMessage, WebSocketConnectionStatus } from '../../models';
import { BluetoothDevice } from '../../models/bluetooth-device.model';
import { DeviceLocationScheduler } from '../../models/device-solution-scheduler.model';
import { GeoJSON } from '../../models/geojson.model';
import { BrowserUtils, CaseUtils, DateUtils, LogUtils, RingBuffer } from '../../utils';
import { DeviceService, EnrollService } from '../api';
import { BusyService } from '../busy/busy.service';
import { LocalSettingsService } from '../local-settings/local-settings.service';
import { PluginService, PluginType } from '../plugin/plugin.service';
import { ClientAuthService } from '../protobuf/client-auth.service';
import { LayoutLocationPointsService } from '../protobuf/layout-location-points.service';
import { TextService } from '../text/text.service';
import { ThemeService } from '../theme/theme.service';
import { WebSocketClientService } from '../web-socket/web-socket-client.service';
import { WebSocketDataService } from '../web-socket/web-socket-data.service';
import { GeolocationService } from './geolocation.service';
import { HeartbeatService } from './heartbeat.service';
import { NotificationService } from './notification.service';
import { OpsStatsService, OpsStatsType } from './ops-stats.service';
import { TranslateService } from './translate.service';
const { name, version } = require('../../../../../package.json');

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private readonly heartbeatIntervalInMs = 30 * 1000;

  private backButtonClickSubject = new Subject<void>();

  private deviceEnrollment: DeviceEnrollment;
  private deviceEnrollmentSubject = new Subject<DeviceEnrollment>();
  private deviceReload: boolean;
  private focusActiveControlSubject = new Subject<void>();

  private initWebSocketConnectionRequestTime: number;
  private isBatteryLowSubject = new BehaviorSubject(false);
  private isConnectionToServerActiveSubject = new BehaviorSubject(true);
  isPersistentErrorPopupShowing: boolean;
  private lastKnownLocationPoints: RingBuffer<GeoJSON>;
  private layoutLoadSubject = new Subject<RuntimeLayoutLoad>();
  private privacyLevelTimeoutDateTimeTimeout: any;
  private readonlySubject = new Subject<boolean>();
  private refreshScannerPluginsSubject = new Subject<boolean>();
  private samsungXCoverKeyPressedSubject = new Subject<void>();
  private socketClientSubscriptions: Subscription[] = [];
  private socketDataSubscriptions: Subscription[] = [];
  private solutionInfoMessage: SolutionInfoMessage;
  private solutionInfoToast: HTMLIonToastElement;
  private solutionChangeConfirmDialog: HTMLIonAlertElement;
  private statusBarMessageSubject = new Subject<string>();
  private watchPositionSubscription: Subscription;

  private layoutSnapshot: RuntimeLayoutSnapshot;

  private _isAppInForeground: boolean;
  set isAppOnline(value: boolean) {
    this._isAppOnline = value;
  }
  get isAppOnline(): boolean {
    return this._isAppOnline;
  }
  private _isAppOnline: boolean;

  private heartbeatTimeout: any;
  private sendLocationInterval: any;
  private sendLocationIntervalIsRunning: boolean;

  inputBufferingSetValues: DictString<string[]> = {};

  constructor(
    private androidFullScreen: AndroidFullScreen,
    private busyService: BusyService,
    private clientAuthService: ClientAuthService,
    private deviceService: DeviceService,
    private enrollService: EnrollService,
    private geolocationService: GeolocationService,
    private heartbeatService: HeartbeatService,
    private keyboardService: KeyboardService,
    private layoutLocationPointsService: LayoutLocationPointsService,
    private localSettingsService: LocalSettingsService,
    private menuController: MenuController,
    private ngZone: NgZone,
    private notificationService: NotificationService,
    private opsStatsService: OpsStatsService,
    private platform: Platform,
    private pluginService: PluginService,
    private popoverCtrl: PopoverController,
    private router: Router,
    private screenOrientation: ScreenOrientation,
    private statusBar: StatusBar,
    private textService: TextService,
    private themeService: ThemeService,
    private translateService: TranslateService,
    private webSocketClientService: WebSocketClientService,
    private webSocketDataService: WebSocketDataService,
  ) {
    this._isAppInForeground = true;
  }

  initService() {
    this.heartbeatService.init(this);

    if (this.solutionChangeConfirmDialog) {
      this.solutionChangeConfirmDialog.dismiss();
    }

    LogUtils.log(name + '@' + version);
    LogUtils.log('Chrome Version: ' + BrowserUtils.getChromeVersionString());
    LogUtils.log('Serving on: ' + window.location.href);

    this.setFullScreen(this.localSettingsService.get().fullScreen);
    this.setScreenOrientation(this.localSettingsService.get().screenOrientation)

    this.heartbeatService.scheduleNextHeartbeat();

    if ((navigator as any).getBattery) {
      try {
        (navigator as any).getBattery()
        .then((bm: any) => {
          this.handleBatteryChangeEvent(bm);

          bm.onlevelchange = (ev: any) => {
            this.handleBatteryChangeEvent(ev.target);
          };
        })
        .catch((error) => {
          LogUtils.warn('getBattery():', error);
        });
      } catch (error) {
        LogUtils.warn('getBattery():', error);
      }
    }

    if (!this.platform.is('cordova')) return;

    this.opsStatsService.addValue(OpsStatsType.AppAliveStateChanges, 'alive');

    this.platform.pause.subscribe(() => {
      LogUtils.log('App paused...');
      this._isAppInForeground = false;
      this.opsStatsService.addValue(OpsStatsType.AppAliveStateChanges, 'paused');
      this.opsStatsService.addValue(OpsStatsType.AppOnlineStateChanges, 'paused');
      this.heartbeatService.stopHeartbeat();
    });

    this.platform.resume.subscribe(() => {
      LogUtils.log('App resumed...');
      this._isAppInForeground = true;
      this.opsStatsService.addValue(OpsStatsType.AppOnlineStateChanges, this.isAppOnline ? 'online' : 'offline');
      this.opsStatsService.addValue(OpsStatsType.AppAliveStateChanges, 'alive');
      this.heartbeatService.scheduleNextHeartbeat(1000);

      this.setFullScreen(this.localSettingsService.get().fullScreen);

      this.refreshScannerPlugins();
    });
  }

  private handleBatteryChangeEvent(bm: any) {
    if (bm.level <= 0.1 && !bm.charging) {
      this.isBatteryLowSubject.next(true);
    } else {
      this.isBatteryLowSubject.next(false);
    }
  };

  isAppInForeground() {
    return this._isAppInForeground;
  }

  isBatteryLow(): Observable<boolean> {
    return this.isBatteryLowSubject.asObservable();
  }

  emitConnectionToServerActive(isAppOnline: boolean): void {
    this.isConnectionToServerActiveSubject.next(isAppOnline);
  }

  isConnectionToServerActive(): Observable<boolean> {
    return this.isConnectionToServerActiveSubject.asObservable();
  }

  deviceEnrollmentChanges(): Observable<DeviceEnrollment> {
    return this.deviceEnrollmentSubject.asObservable();
  }

  listenToBackButtonClick(): Observable<void> {
    return this.backButtonClickSubject.asObservable();
  }

  backButtonClick(): void {
    if (this.backButtonClickSubject.observers.length) {
      this.backButtonClickSubject.next();
    } else if (this.keyboardService.isVisible()) {
      this.keyboardService.hide();
    } else {
      navigator['app'].exitApp(); // Ionic 4
    }
  }

  listenToRefreshScannerPlugins(): Observable<boolean> {
    return this.refreshScannerPluginsSubject.asObservable();
  }

  refreshScannerPlugins(skipConsumingScans?: boolean): void {
    this.refreshScannerPluginsSubject.next(skipConsumingScans);
  }

  getDeviceEnrollment(): DeviceEnrollment {
    return this.deviceEnrollment;
  }

  getLayoutSnapshot(): RuntimeLayoutSnapshot {
    return this.layoutSnapshot;
  }

  listenToFocusActiveControl(): Observable<void> {
    return this.focusActiveControlSubject.asObservable();
  }

  focusActiveControl(): void {
    this.focusActiveControlSubject.next();
  }

  layoutLoadChanges(): Observable<RuntimeLayoutLoad> {
    return this.layoutLoadSubject.asObservable();
  }

  readonlyChanges(): Observable<boolean> {
    return this.readonlySubject.asObservable();
  }

  listenToStatusBarMessageChanges(): Observable<string> {
    return this.statusBarMessageSubject.asObservable();
  }

  setStatusBarMessage(msg: string): void {
    this.statusBarMessageSubject.next(msg);
  }

  enrollAndInitDevice(): void {
    setTimeout(() => {
      this.initPlugins();

      this.enrollService.getLocalEnrollment()
      .pipe(
        mergeMap(this.enrollIfNotAlready.bind(this)),
      )
      .subscribe((de: DeviceEnrollment) => {
        this.applyDeviceEnrollmentInternally(de, false);
      },
      (error: any) => {
        this.notificationService.showNotification(new Notification({
          title: this.translateService.instant('Notification'),
          text: this.translateService.instant('Failed to enroll and init device'),
          type: RuntimeLayoutNotifyType.CriticalAlert,
          blocking: true
        }));
      });
    }, 10);
  }

  refreshDeviceEnrollment(): Observable<DeviceEnrollment> {
    return this.enrollService.clearLocalEnrollment()
    .pipe(
      mergeMap(() => {
        return this.enrollService.refresh(this.deviceEnrollment);
      }),
      map((de: DeviceEnrollment) => {
        this.applyDeviceEnrollmentInternally(de, true);
        return de;
      }),
      catchError((error: any) => {
        this.notificationService.showNotification(new Notification({
          title: this.translateService.instant('Notification'),
          text: this.translateService.instant('Failed to refresh device enrollment.'),
          type: RuntimeLayoutNotifyType.CriticalAlert,
          blocking: true
        }));
        return throwError(error);
      })
    );
  }

  private applyDeviceEnrollmentInternally(de: DeviceEnrollment, isRefresh: boolean) {
    if (!de) {
      this.router.navigate(['enroll-new'], {
        replaceUrl: true,
        queryParams: BrowserUtils.getQueryParams(),
      });
      return;
    }

    if (de.solutionEnvironmentOverrideShow && de.solutionEnvironmentColor) {
      this.themeService.setVariable('--lc-header-background', de.solutionEnvironmentColor);
    } else {
      this.themeService.setVariable('--lc-header-background', undefined);
    }

    if (BrowserUtils.isDeviceApp() && this.platform.is('android') && de.deviceIndustrialMode) {
      const settings = this.localSettingsService.get();
      settings.fullScreen = true;
      this.localSettingsService.set(settings)
      .subscribe(() => {
        this.setFullScreen(settings.fullScreen);
      });
    }

    if (de.$deviceRunStatus === DeviceRunStatus.Deactivated) {
      this.readonlySubject.next(false);
      this.enrollService.clearLocalEnrollment()
      .subscribe(() => {
        this.enrollAndInitDevice();
      });
    } else if ((de.$deviceRunStatus || DeviceRunStatus.Alive) === DeviceRunStatus.Alive) {
      if (isRefresh) {
        this.deviceEnrollment = de;
        this.deviceEnrollmentSubject.next(this.deviceEnrollment);
      } else {
        this.initWsAndAuthenticate(de).subscribe();
      }
    } else {
      this.deviceEnrollment = de;
      this.deviceEnrollmentSubject.next(this.deviceEnrollment);
      this.readonlySubject.next(true);
    }
  }

  private initPlugins() {
    const btPlugin = this.pluginService.getInstance(PluginType.Bluetooth);
    const satoPlugin = this.pluginService.getInstance(PluginType.Sato);
    zip(
      btPlugin.initialize(),
      satoPlugin.initialize()
    ).subscribe();

    if (!BrowserUtils.isDeviceApp()) return;

    const honeywellPlugin = this.pluginService.getInstance(PluginType.Honeywell);
    const nfcPlugin = this.pluginService.getInstance(PluginType.NFC);
    const pointMobilePlugin = this.pluginService.getInstance(PluginType.PointMobile);
    const zebraPlugin = this.pluginService.getInstance(PluginType.Zebra);

    zip(
      honeywellPlugin.initialize(),
      nfcPlugin.initialize(),
      pointMobilePlugin.initialize(),
      zebraPlugin.initialize(),
    ).subscribe();
  }

  private enrollIfNotAlready(de: DeviceEnrollment): Observable<DeviceEnrollment> {
    const queryParams = BrowserUtils.getQueryParams();

    if (new Date(de?.enrollTimeoutDateTime) < new Date()) {
      return this.enrollService.refresh(de);
    } else if ( // app or runDevice
      BrowserUtils.isDeviceApp() ||
      !!queryParams.runDevice /*||
      (BrowserUtils.isLocalhost() && de)*/
    ) {
      if (de) {
        if (de.$enrollmentKey && de.$runDevice == queryParams.runDevice) {
          LogUtils.log('AppDevice already enrolled with enrollment key.');
        } else if (de.$deviceOemGuidId) {
          LogUtils.log('AppDevice already enrolled with device OEM guidId.');
        } else {
          return of(null);
        }
        return of(de);
      } else {
        if (oem.deviceOemGuidId) {
          LogUtils.log('Enrolling OEM AppDevice with guidId: ' + oem.deviceOemGuidId);
          return this.enrollService.oem(
            oem.deviceOemGuidId,
            oem.runClientType
          );
        } else {
          return of(null);
        }
      }
    } else { // browser
      const settings = this.localSettingsService.get();
      settings.runDeviceDebug = !!queryParams.runDeviceDebug;
      this.localSettingsService.set(settings).subscribe();

      let runCode = queryParams.runCode;
      let runSet = queryParams.runSet;
      let runSetSubKey = queryParams.runSetSubKey;
      if (!runCode && !runSet && !environment.production) {
        runCode = 'VWMjJ5pbHEaT3zSFuvwSgQ';
        runSet = '';
      }

      if (runCode || runSet) {
        if (de?.$runCode && de.$runCode === runCode) {
          LogUtils.log('WebDevice already enrolled with runCode: ' + de.$runCode);
          return of(de);
        } else if (de?.$runSet && de.$runSet === runSet && de.$runSetSubKey === runSetSubKey) {
          LogUtils.log('WebDevice already enrolled with runSet: ' + (de.$runSet || '') + ' ' + (de.$runSetSubKey || ''));
          return of(de);
        } else {
          LogUtils.log('Enrolling WebDevice with runCode / runSet: ' + (runCode || runSet) + ' ' + (runSetSubKey || ''));
          this.textService.clearLocalCache();
          return this.deviceService.web(runCode, runSet, runSetSubKey);
        }
      } else {
        this.notificationService.showNotification(new Notification({
          title: this.translateService.instant('Notification'),
          text: this.translateService.instant('Missing WebDevice RunCode or RunSet!'),
          type: RuntimeLayoutNotifyType.CriticalAlert,
          blocking: true,
        }));
        return EMPTY; // this skips the initWSAndAuth...
      }
    }
  }

  isEnrolled(): boolean {
    return !!this.deviceEnrollment;
  }

  deviceRestart(factoryReset: boolean) {
    this.inputBufferingSetValues = {};

    this.enrollService.getLocalEnrollment()
    .subscribe((de: DeviceEnrollment) => {
      if (!de) {
        LogUtils.warn('deviceRestart(): Missing deviceEnrollment...');
        return;
      }

      LogUtils.log(`Device restart command sent (factoryReset=${factoryReset}).`);
      this.deviceService.restart(de.enrollmentGuidId, factoryReset)
      .subscribe((hrr: HostRestartResponse) => {
        LogUtils.log(`Device restarted: ${hrr.restarted || false}`);
        this.initWsAndAuthenticate(de).subscribe();
      }, (error: any) => {
        this.notificationService.showNotification(new Notification({
          title: this.translateService.instant('Notification'),
          text: error?.errorMessage || error,
          type: RuntimeLayoutNotifyType.CriticalAlert,
          blocking: true
        }));
        LogUtils.error(error);
      });
    });
  }

  initWsAndAuthenticate(de: DeviceEnrollment): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      this.deviceEnrollment = undefined;
      this.layoutSnapshot = undefined;

      if (!de?.layoutHostUri) {
        LogUtils.warn('initWsAndAuthenticate() called with no deviceEnrollment...doing nothing.');
        observer.next(null);
        observer.complete();
        return;
      }

      this.initWebSocketConnection(de.layoutHostUri)
      .subscribe(() => {
        LogUtils.log('WebSocket connection initialized.');
        this.busyService.setBusy(
          true,
          this.translateService.instant('Connecting to server...')
        );

        this.deviceEnrollment = de;
        this.deviceEnrollmentSubject.next(this.deviceEnrollment);

        observer.next(null);
        observer.complete();
      }, (error: any) => {
        this.notificationService.showNotification(new Notification({
          title: this.translateService.instant('Notification'),
          text: error?.errorMessage || error,
          type: RuntimeLayoutNotifyType.CriticalAlert,
          blocking: true
        }));

        observer.next(error);
        observer.complete();
      });
    });
  }

  private initWebSocketConnection(wsUrl: string): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      // if there are any subscriptions, remove them first as
      // having a subscription is what keeps the websocket open
      this.clearClientSubscriptionsAndDisconnect();

      setTimeout(() => {
        this.initWebSocketConnectionRequestTime = performance.now();
        this.webSocketClientService.connect(wsUrl);

        this.socketClientSubscriptions.push(
          this.webSocketClientService.getConnectionStatusChanges()
          .subscribe({
            next: this.handleClientConnectionChange.bind(this),
            error: this.handleError.bind(this),
          })
        );

        this.socketClientSubscriptions.push(
          this.webSocketClientService.getMessages$()
          .subscribe({
            next: this.handleIncomingClientMessage.bind(this),
            error: this.handleError.bind(this),
          })
        );

        observer.next();
        observer.complete();
      }, 250);
    });
  }

  private initWebSocketDataConnection(wsUrl: string): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      // if there are any subscriptions, remove them first as
      // having a subscription is what keeps the websocket open
      this.clearDataSubscriptionsAndDisconnect();

      setTimeout(() => {
        this.initWebSocketConnectionRequestTime = performance.now();
        this.webSocketDataService.connect(wsUrl);

        this.socketDataSubscriptions.push(
          this.webSocketDataService.getConnectionStatusChanges()
          .subscribe({
            next: this.handleDataConnectionChange.bind(this),
            error: () => {},
          })
        );

        this.socketDataSubscriptions.push(
          this.webSocketDataService.getMessages$()
          .subscribe({
            next: this.handleIncomingDataMessage.bind(this),
            error: () => {},
          })
        );

        observer.next();
        observer.complete();
      }, 100);
    });
  }

  clearClientSubscriptionsAndDisconnect() {
    if (!this.socketClientSubscriptions) return;

    for (const sub of this.socketClientSubscriptions) {
      sub.unsubscribe();
    };
    this.socketClientSubscriptions.length = 0;

    // TODO: the above is not really true for unsubscribes...only for errors which forces the websocket to complete...
    // so until I find a better approach to implement this...call websocket disconnet.
    this.webSocketClientService.disconnect();
  }

  clearDataSubscriptionsAndDisconnect() {
    if (this.socketDataSubscriptions) {
      for (const sub of this.socketDataSubscriptions) {
        sub.unsubscribe();
      };
      this.socketDataSubscriptions.length = 0;

      // TODO: the above is not really true for unsubscribes...only for errors which forces the websocket to complete...
      // so until I find a better approach to implement this...call websocket disconnet.
      this.webSocketDataService.disconnect();
    }
  }

  private handleClientConnectionChange(wscs: WebSocketConnectionStatus) {
    LogUtils.log('Client Connection status to Server: ' + WebSocketConnectionStatus[wscs]);
    if (wscs === WebSocketConnectionStatus.Closed) {
      this.opsStatsService.addValue(OpsStatsType.WsDisconnectsCount, 1);
      LogUtils.log('Connecting to server...');
      // this.busyService.setBusy(
      //   true,
      //   this.translateService.instant('Connecting to server...')
      // );
    } else if (wscs === WebSocketConnectionStatus.Open) {
      this.busyService.setBusy(false);
      this.opsStatsService.addValue(OpsStatsType.WsConnectionTimeInMs, ~~(performance.now() - this.initWebSocketConnectionRequestTime));

      this.enrollService.getLocalEnrollment()
      .pipe(delay(10))
      .subscribe((de: DeviceEnrollment) => {
        this.deviceEnrollment = this.deviceEnrollment || de;
        if (!this.deviceEnrollment?.enrollmentGuidId) return;

        this.clientAuthService.auth(
          this.textService.languageGuidId,
          this.deviceEnrollment,
          false,
          this.deviceReload,
          this.layoutSnapshot && this.layoutSnapshot.runtimeLayout ? this.layoutSnapshot.snapshotTick || this.layoutSnapshot.runtimeLayout.tick : undefined,
        )
        .subscribe((response: AuthResponse) => {
          LogUtils.log('Client (re)authenticated:', response);
          if (response.deviceRunStatus === DeviceRunStatus.Deactivated) {
            this.readonlySubject.next(false);
            this.enrollService.clearLocalEnrollment()
            .subscribe(() => {
              this.enrollAndInitDevice();
            });
            return;
          } else if ((response.deviceRunStatus || DeviceRunStatus.Alive) !== DeviceRunStatus.Alive) {
            this.deviceEnrollment.$deviceRunStatus = response.deviceRunStatus;
            this.deviceEnrollmentSubject.next(this.deviceEnrollment);
          } else if (!this.layoutSnapshot?.runtimeLayout) {
            this.busyService.setBusy(
              true,
              this.translateService.instant('Waiting for Snapshot...')
            );
          }

          this.readonlySubject.next(response.readOnly);
          this.deviceReload = false;
        }, (error: any) => {
          LogUtils.error('Client authentication error:', error?.errorMessage || error);
          if (error === 'Timeout while authenticating device.') {
            this.initWsAndAuthenticate(de).subscribe();
          }
        });
      });
    }
  }

  private handleDataConnectionChange(wscs: WebSocketConnectionStatus) {
    LogUtils.log('Data Connection status to Server: ' + WebSocketConnectionStatus[wscs]);
    setTimeout(() => {
      if (wscs === WebSocketConnectionStatus.Open) {
        this.enrollService.getLocalEnrollment()
        .subscribe((de: DeviceEnrollment) => {
          this.deviceEnrollment = this.deviceEnrollment || de;

          if (!this.deviceEnrollment?.enrollmentGuidId) return;

          this.clientAuthService.auth(
            this.textService.languageGuidId,
            this.deviceEnrollment,
            true,
          )
          .subscribe((response: AuthResponse) => {
            LogUtils.log('Data (re)authenticated:', response);
          }, (error: any) => {
            LogUtils.error('Data authentication error:', error?.errorMessage || error);

            if (error === 'Timeout while authenticating device.') {
              this.initWebSocketDataConnection(de.layoutHostUri + 'data').subscribe();
            }
          });
        });
      }
    }, 10);
  }

  // this is connected once and should in theory get all incoming messages,
  // even the ones that are handled on specialized services like layoutEvents and clientAuth...
  private handleIncomingClientMessage(msg: LayoutCoreMessage) {
    if (!msg) return;

    const networkInfo = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;
    if (networkInfo) {
      this.opsStatsService.addValue(OpsStatsType.NetworkInfoDownlink, networkInfo.downlink);
      this.opsStatsService.addValue(OpsStatsType.NetworkInfoRTT, networkInfo.rtt);
    }

    this.isAppOnline = true;
    this.opsStatsService.addValue(OpsStatsType.AppOnlineStateChanges, 'online');
    this.isConnectionToServerActiveSubject.next(this.isAppOnline);

    if (!msg?.messageType) return;
    if (!msg?.replyMessage) this.busyService.setBusy(false);

    switch (msg.messageType) {
      case LayoutMessageType.Load:
          console.log('Received Load Message.');
          const llm = RuntimeLayoutLoad.decode(msg.messageContent);
          LogUtils.log('LayoutLoad Received: ', llm);

          this.layoutLoadSubject.next(llm);
          break;
      case LayoutMessageType.Snapshot:
          console.log('Received Snapshot Message.');
          const layoutSnapshotUpdate = RuntimeLayoutSnapshot.decode(msg.messageContent);
          LogUtils.log('LayoutSnapshot received with tick: ', layoutSnapshotUpdate.snapshotTick);

          this.handleSnapshotMessage(layoutSnapshotUpdate);
          break;
      case LayoutMessageType.SolutionInfo:
          console.log('Received SolutionInfo Message.');
          const sim = SolutionInfoMessage.decode(msg.messageContent);
          LogUtils.log('SolutionInfoMessage Received: ', sim);

          if (sim.colorOverrideShow && sim.color) {
            this.themeService.setVariable('--lc-header-background', sim.color);
          }

          this.solutionInfoMessage = sim;
          this.updateLocalDeviceEnrollment(sim)
          break;
      case LayoutMessageType.SolutionChange:
          console.log('Received SolutionChange Message.');
          const scm = SolutionChangeMessage.decode(msg.messageContent);
          LogUtils.log('SolutionChangeMessage Received: ', scm);

          this.handleSolutionChangeMessage(scm);
          break;
      case LayoutMessageType.ChangeEnrollment:
          console.log('Received ChangeEnrollment Message.');
          const lcem = LayoutChangeEnrollmentMessage.decode(msg.messageContent);
          LogUtils.log('ChangeEnrollmentMessage Received: ', lcem);

          this.clientAuthService.auth(
            this.textService.languageGuidId,
            this.deviceEnrollment,
            false,
            false,
            this.layoutSnapshot.snapshotTick || this.layoutSnapshot.runtimeLayout.tick,
            lcem.newEnrollmentGuidId,
            this.deviceEnrollment.enrollmentGuidId,
          )
          .subscribe((response: AuthResponse) => {
            LogUtils.log('Client re-authenticated:', response);
            if ((response.deviceRunStatus || DeviceRunStatus.Alive) !== DeviceRunStatus.Alive) {
              this.deviceEnrollment.$deviceRunStatus = response.deviceRunStatus;
              this.deviceEnrollmentSubject.next(this.deviceEnrollment);
            } else {
              this.busyService.setBusy(
                true,
                this.translateService.instant('Waiting for Snapshot...')
              );
            }
            this.readonlySubject.next(response.readOnly);
          }, (error: any) => {
            LogUtils.error('Client authentication error:', error?.errorMessage || error);
            if (error === 'Timeout while authenticating device.') {
              this.initWsAndAuthenticate(this.deviceEnrollment).subscribe();
            }
          });
          break;
      case LayoutMessageType.CriticalError:
          LogUtils.log('Received CriticalError Message.');
          const error = CriticalErrorMessage.decode(msg.messageContent);

          this.handleError(error, true);
          break;
      default:
        if (msg.replyMessage) {
          if (msg.messageResult === LayoutMessageResult.NotAuthenticated) {
            this.initWsAndAuthenticate(this.deviceEnrollment).subscribe();
          } else if (msg.messageResult === LayoutMessageResult.Error) {
            this.notificationService.showNotification(new Notification({
              title: this.translateService.instant('Notification'),
              text: msg.errorMessage || this.translateService.instant('Unknown error'),
              type: RuntimeLayoutNotifyType.Alert,
              blocking: false,
            }));
          } else if (msg.messageResult !== LayoutMessageResult.Success) {
            LogUtils.warn('Unexpected Client MessageResult: ', msg.toJSON());
          }
        } else if (!msg.replyMessage) {
          LogUtils.warn('Unexpected Client MessageType: ', msg.toJSON());
        }
        break;
    }
  }

  private handleIncomingDataMessage(msg: LayoutCoreMessage) {
    if (!msg) return;
    if (!msg?.messageType) {
      LogUtils.warn('Unexpected Data MessageType: ', msg.toJSON());
      return;
    }
    if (!msg?.replyMessage) {
      LogUtils.warn('Unexpected Data ReplyMessage: ', msg.toJSON());
      return;
    }

    if (msg.messageResult === LayoutMessageResult.NotAuthenticated && this.deviceEnrollment) {
      this.initWebSocketDataConnection(this.deviceEnrollment.layoutHostUri + 'data').subscribe();
    } else if (msg.messageResult !== LayoutMessageResult.Success) {
      LogUtils.warn('Unexpected Data MessageResult: ', msg.toJSON());
    }
  }

  private handleSnapshotMessage(layoutSnapshotUpdate: RuntimeLayoutSnapshot) {
    let isFullLayout = false;

    const layoutSnapshotUpdateSize = this.sizeOfLayoutSnapshot(layoutSnapshotUpdate);
    if (this.layoutSnapshot?.runtimeLayout) {
      // check if the update can be merged with the current snapshot or if we need to do a
      // full refresh
      if (this.layoutSnapshot.startedTick !== layoutSnapshotUpdate.startedTick) {
        this.initWsAndAuthenticate(this.deviceEnrollment).subscribe();
        return;
      }

      if (
        this.layoutSnapshot.runtimeLayoutSession &&
        layoutSnapshotUpdate.runtimeLayoutSession &&
        this.layoutSnapshot.runtimeLayoutSession.lastResetTick !== layoutSnapshotUpdate.runtimeLayoutSession.lastResetTick
      ) {
        this.localSettingsService.reset();
      }

      this.layoutSnapshot.mergeLayoutSnapshotUpdate(layoutSnapshotUpdate);
    } else {
      this.layoutSnapshot = layoutSnapshotUpdate;

      if (this.layoutSnapshot.runtimeLayout?.customSymbologies && this.layoutSnapshot.runtimeLayout?.customSymbologiesJson) {
        const customSymbologies = JSON.parse(this.layoutSnapshot.runtimeLayout?.customSymbologiesJson);
        if (customSymbologies.type === 'zebra') {
          const zebraPlugin = this.pluginService.getInstance(PluginType.Zebra);
          zebraPlugin.initialize({ customProfile: customSymbologies.profile });
        }
      }

      if (this.layoutSnapshot.runtimeLayoutSession) {
        if (this.layoutSnapshot.runtimeLayoutSession.lastResetTick) {
          this.localSettingsService.reset();
        }
        // this IF is clearly never called as "isFullLayout" is never true...I should prob fix this at some point...
        if (this.layoutSnapshot.runtimeLayoutSession && this.layoutSnapshot.runtimeLayoutSession.settingGroups && isFullLayout) {
          this.handleLayoutSettings(this.layoutSnapshot.runtimeLayoutSession.settingGroups);
        }
      }
    }
    const layoutSnapshotSize = this.sizeOfLayoutSnapshot(this.layoutSnapshot);
    LogUtils.log(`LayoutSnapshotUpdate: ${layoutSnapshotUpdateSize.toFixed(2)}kB, LayoutSnapshotFull: ${layoutSnapshotSize.toFixed(2)}kB`);

    const settings = this.localSettingsService.get();
    if (this.layoutSnapshot.runtimeLayout?.defaultKeyboard != null && !settings.keyboardChangedByUser) {
      settings.keyboard = this.layoutSnapshot.runtimeLayout.defaultKeyboard;
    }

    this.enableDeviceLocationIfRequired();

    this.updateSolutionInfoToast(this.solutionInfoMessage);
    this.solutionInfoMessage = null;
    this.layoutLoadSubject.next(null);

    if (this.layoutSnapshot?.runtimeLayout?.layoutScreens) {
      console.log('LayoutSnapshot Merged: ', this.layoutSnapshot);

      this.router.navigate(['main'], { replaceUrl: true, queryParamsHandling: 'preserve' });
    }
  }

  private sizeOfLayoutSnapshot(layoutSnapshot: RuntimeLayoutSnapshot) {
    const size = new TextEncoder().encode(JSON.stringify(layoutSnapshot.toJSON())).length;
    const kiloBytes = size / 1024;
    return kiloBytes;
    // const megaBytes = kiloBytes / 1024;
    // return megaBytes;
  }

  private enableDeviceLocationIfRequired() {
    if (
      !this.layoutSnapshot?.runtimeLayout?.deviceLocation ||
      !this.layoutSnapshot?.runtimeLayout?.deviceLocationScheduler
    ) {
      this.stopDeviceLocationCapture();
      return;
    } else if (
      this.layoutSnapshot?.runtimeLayout?.privacyLevel &&
      this.layoutSnapshot?.runtimeLayout?.privacyLevelTimeoutDateTime &&
      new Date() < new Date(this.layoutSnapshot?.runtimeLayout?.privacyLevelTimeoutDateTime)
    ) {
      this.stopDeviceLocationCapture();
      if (this.privacyLevelTimeoutDateTimeTimeout) clearTimeout(this.privacyLevelTimeoutDateTimeTimeout);
      this.privacyLevelTimeoutDateTimeTimeout = setTimeout(() => {
        this.enableDeviceLocationIfRequired();
      }, (new Date(this.layoutSnapshot?.runtimeLayout?.privacyLevelTimeoutDateTime)).getTime() - Date.now());
      return;
    }

    const now = new Date();
    const currentDayOfWeek = (now.getDay() + 1);
    const scheduler = new DeviceLocationScheduler(CaseUtils.toCamel(JSON.parse(this.layoutSnapshot.runtimeLayout.deviceLocationScheduler)));
    const defaultSchedule = scheduler.schedulers.find(s => s.default);
    let activeSchedule = defaultSchedule;
    for (const schedule of scheduler.schedulers || []) {
      if (!schedule.timeDaysOfWeek || (schedule.timeDaysOfWeek & currentDayOfWeek) === currentDayOfWeek) {
        if (schedule.timeHourStart && schedule.timeHourEnd && schedule.timeHourStart !== schedule.timeHourEnd) {
          const startHours = parseInt(schedule.timeHourStart.split(':')[0]);
          const startMinutes = parseInt(schedule.timeHourStart.split(':')[1]);
          const endHours = parseInt(schedule.timeHourEnd.split(':')[0]);
          const endMinutes = parseInt(schedule.timeHourEnd.split(':')[1]);
          if (
            now.getHours() >= startHours && now.getHours() >= startMinutes &&
            now.getHours() <= endHours && now.getHours() <= endMinutes
          ) {
            activeSchedule = schedule;
            break;
          }
        } else if (schedule.timeHourStart && (!schedule.timeHourEnd || schedule.timeHourEnd !== '00:00:00')) {
          const startHours = parseInt(schedule.timeHourStart.split(':')[0]);
          const startMinutes = parseInt(schedule.timeHourStart.split(':')[1]);
          if (now.getHours() >= startHours && now.getHours() >= startMinutes) {
            activeSchedule = schedule;
            break;
          }
        } else if (schedule.timeHourEnd && (!schedule.timeHourStart || schedule.timeHourStart !== '00:00:00')) {
            const endHours = parseInt(schedule.timeHourEnd.split(':')[0]);
            const endMinutes = parseInt(schedule.timeHourEnd.split(':')[1]);
            if (now.getHours() <= endHours && now.getHours() <= endMinutes) {
              activeSchedule = schedule;
              break;
            }
        } else {
          activeSchedule = schedule;
          break;
        }
      }
    }

    if (!activeSchedule?.gpsEnabled || /*!activeSchedule.gpsTimeSpanInSeconds && */!activeSchedule?.sendTimeSpanInSeconds) {
      this.stopDeviceLocationCapture()
      return;
    }
    if (this.watchPositionSubscription && !this.watchPositionSubscription.closed) return;

    this.enrollService.getLocalEnrollment()
    .pipe(
      delay(1000),
      mergeMap((de: DeviceEnrollment) => {
        return this.initWebSocketDataConnection(de.layoutHostUri + 'data');
      })
    )
    .subscribe(() => {
      this.geolocationService.start();
      this.watchPositionSubscription = this.geolocationService.watchPosition()
      .subscribe((geoJSON: GeoJSON) => {
        if (!geoJSON) return;

        const now = new Date();
        if (
          !activeSchedule.gpsTimeSpanInSeconds ||
          !this.lastKnownLocationPoints?.length ||
          now.getTime() - ((new Date(this.lastKnownLocationPoints[0].properties.deviceDateTime)).getTime()) >= activeSchedule.gpsTimeSpanInSeconds * 1000
        ) {
          this.lastKnownLocationPoints = RingBuffer.fromPlain(this.lastKnownLocationPoints || [], 100);
          this.lastKnownLocationPoints.push(geoJSON);
          LogUtils.log(`Got geolocation event (${this.lastKnownLocationPoints.length}): `, geoJSON);
        }
      });

      this.sendLocationInterval = setInterval(() => {
        if (this.sendLocationIntervalIsRunning) return;
        if (!this.lastKnownLocationPoints?.length) return;

        this.sendLocationIntervalIsRunning = true;
        this.layoutLocationPointsService.trigger(
          this.lastKnownLocationPoints.slice(0),
        ).subscribe((result: boolean) => {
          this.sendLocationIntervalIsRunning = false;
          if (!result) return;
          this.lastKnownLocationPoints.length = 0;
        }, (error: any) => {
          this.sendLocationIntervalIsRunning = false;
          LogUtils.error('layoutLocationPointsService.trigger() error:', error);
        });
      }, activeSchedule.sendTimeSpanInSeconds * 1000);
    });
  }

  private stopDeviceLocationCapture() {
    if (this.watchPositionSubscription) {
      this.watchPositionSubscription.unsubscribe();
      this.watchPositionSubscription = null;
    }

    if (this.sendLocationInterval) {
      clearInterval(this.sendLocationInterval);
      this.sendLocationInterval = undefined;
    }
    this.geolocationService.stop();
  }

  updateSolutionInfoToast(sim: SolutionInfoMessage) {
    if (this.solutionInfoToast) {
      this.solutionInfoToast.dismiss();
      this.solutionInfoToast = null;
    }

    if (
      sim?.deviceSolutionVersion &&
      (
        !this.deviceEnrollment ||
        (this.deviceEnrollment as any).$previousDeviceSolutionGuidId !== sim.deviceSolutionGuidId ||
        (this.deviceEnrollment as any).$previousDeviceSolutionTick !== sim.deviceSolutionTick
      )
    ) {
      this.notificationService.showToast(
        `${this.translateService.instant('App Version')}: ${sim.deviceSolutionVersion}`,
      ).subscribe((toast: HTMLIonToastElement) => {
        this.solutionInfoToast = toast;
      });
    }
  }

  private handleSolutionChangeMessage(scm: SolutionChangeMessage) {
    let confirmOrSkipObservable = null;
    // if not layout or layout with only 1 screen and that screen is of type Login...
    // then skip the confirmation dialog
    if (
      !Object.keys(this.layoutSnapshot?.runtimeLayout?.layoutScreens || {}).length ||
      (
        Object.keys(this.layoutSnapshot.runtimeLayout.layoutScreens).length === 1 &&
        this.layoutSnapshot.runtimeLayout.layoutScreens[Object.keys(this.layoutSnapshot.runtimeLayout.layoutScreens)[0]].flowSolutionType === RuntimeLayoutScreenFlowSolutionType.Logon
      )
    ) {
      confirmOrSkipObservable = of(true);
    } else {
      if (this.solutionChangeConfirmDialog) {
        this.solutionChangeConfirmDialog.dismiss();
      }
      confirmOrSkipObservable = this.notificationService.showConfirm(
        this.translateService.instant('Solution Changed'),
        `${this.translateService.instant('Solution')} ${scm.deviceSolutionName} ${this.translateService.instant('has just been updated to version')} ${scm.deviceSolutionVersion} (${scm.deviceSolutionSetSysVersion})<br>`, // `${this.translateService.instant('Solution')} ${scm.deviceSolutionName} ${this.translateService.instant('has just been updated to version')} ${scm.deviceSolutionVersion} (${scm.deviceSolutionSetSysVersion})<br><br><b>${this.translateService.instant('Do you wish to restart or continue with current version?')}</b>`,
        this.translateService.instant('Restart'),
        '', // this.translateService.instant('Continue'),
      ).pipe(
        mergeMap((confirm: HTMLIonAlertElement) => {
          this.solutionChangeConfirmDialog = confirm;

          return (this.solutionChangeConfirmDialog as any).$result;
        })
      );
    }

    confirmOrSkipObservable
    .subscribe((restart: boolean) => {
      if (this.solutionChangeConfirmDialog) {
        this.solutionChangeConfirmDialog.dismiss();
      }

      if (!restart) return;

      if (scm.colorOverrideShow && scm.color) {
        this.themeService.setVariable('--lc-header-background', scm.color);
      }

      this.layoutSnapshot = undefined;
      this.router.navigate(['main'], { replaceUrl: true, queryParamsHandling: 'preserve' });

      this.deviceReload = true;
      this.deviceEnrollment.deviceSolutionVersion = scm.deviceSolutionVersion;
      this.initWsAndAuthenticate(this.deviceEnrollment).subscribe();
    });
  }

  private updateLocalDeviceEnrollment(sim: SolutionInfoMessage) {
    if (this.deviceEnrollment) {
      (this.deviceEnrollment as any).$previousDeviceSolutionGuidId = this.deviceEnrollment.deviceSolutionGuidId;
      (this.deviceEnrollment as any).$previousDeviceSolutionTick = this.deviceEnrollment.deviceSolutionTick;
      Object.assign(this.deviceEnrollment, sim);

      this.enrollService.localUpdate(this.deviceEnrollment);
      this.deviceEnrollmentSubject.next(this.deviceEnrollment);
    }
  }

  private handleLayoutSettings(settingGroups: DictNumber<RuntimeLayoutSettingGroup>) {
    const settings = this.localSettingsService.get();
    for (const key of Object.keys(settingGroups)) {
      if (
        this.deviceEnrollment &&
        settingGroups[key].path + settingGroups[key].name === 'Platform/Language'
      ) {
        this.textService.languageGuidId = settingGroups[key].settings[0].value;
        this.textService.get(
          this.deviceEnrollment.enrollmentGuidId,
          this.textService.languageGuidId
        )
        .subscribe();
      }

      if (settingGroups[key].path + settingGroups[key].name === 'Platform/Style') {
        settings.theme = settingGroups[key].settings[0].value;
      }

      if (settingGroups[key].path + settingGroups[key].name === 'Platform/FullScreen') {
        settings.fullScreen = settingGroups[key].settings[0].value === 'true';
      }

      if (settingGroups[key].path + settingGroups[key].name === 'Platform/ScreenOrientation') {
        settings.screenOrientation = settingGroups[key].settings[0].value;
      }

      if (settingGroups[key].path === 'Plugin/Bluetooth/') {
        const idSetting = settingGroups[key].settings.find((s: RuntimeLayoutSetting) => {
          return s.name === 'id';
        });
        const modeSetting = settingGroups[key].settings.find((s: RuntimeLayoutSetting) => {
          return s.name === 'mode';
        });
        const nameSetting = settingGroups[key].settings.find((s: RuntimeLayoutSetting) => {
          return s.name === 'name';
        });
        const typeSetting = settingGroups[key].settings.find((s: RuntimeLayoutSetting) => {
          return s.name === 'type';
        });
        this.localSettingsService.addOrUpdateBtDevice({
          id: idSetting.value,
          mode: modeSetting.value,
          name: nameSetting.value,
          type: typeSetting.value,
          shouldBeConnected: true,
          // isConnected: false,
        } as BluetoothDevice);
      }
    }
    this.localSettingsService.commitLocalChanges();
  }

  setFullScreen(active: boolean) {
    if (!this.platform.is('cordova')) return;
    if (!this.platform.is('android')) return;

    zip(
      from(this.androidFullScreen.isSupported()),
      from(this.androidFullScreen.isImmersiveModeSupported())
    ).subscribe(() => {
      LogUtils.log('Full Screen: ' + active);
      if (active) {
        // this.androidFullScreen.setSystemUiVisibility(AndroidSystemUiFlags.HideNavigation);
        this.androidFullScreen.immersiveMode();
      } else {
        this.androidFullScreen.showSystemUI();
      }
    }, () => {
      this.statusBar.isVisible = !active;
    });
  }

  setScreenOrientation(screenOrientation: string) {
    if (!this.platform.is('cordova')) return;

    LogUtils.log('Screen Orientation: ' + screenOrientation);
    this.screenOrientation.lock(screenOrientation);
  }

  private handleError(error: any, isCritical?: boolean) {
    this.busyService.setBusy(false);
    LogUtils.error(error);

    this.notificationService.showNotification(new Notification({
      title: isCritical ? this.translateService.instant('Application Critical') : this.translateService.instant('Notification'),
      text: error?.errorMessage || error,
      type: isCritical ? RuntimeLayoutNotifyType.CriticalServerError : RuntimeLayoutNotifyType.Alert,
      blocking: isCritical,
    }));

    if (this.layoutSnapshot) {
      if (error.causeDisconnect) {
        this.layoutSnapshot = undefined;
      }
    } else if (!this.isPersistentErrorPopupShowing) {
      this.clearClientSubscriptionsAndDisconnect();

      let errorMsg = error?.errorMessage || error || '';
      if (isCritical) {
        const settings = this.localSettingsService.get();
        if (!errorMsg || !settings.runDeviceDebug) {
          errorMsg = this.translateService.instant('Critical Server Error');
        }
      }

      this.showCriticalErrorPopover(errorMsg)
      .subscribe((accessMenu: boolean) => {
        if (accessMenu) {
          this.menuController.open();
        } else {
          this.initWsAndAuthenticate(this.deviceEnrollment).subscribe();
        }
      });
    }
  }

  private showCriticalErrorPopover(errorMsg: string): Observable<boolean> {
    const subject = new Subject<boolean>();

    from(this.popoverCtrl.create({
      component: ConfirmPopover,
      componentProps: {
        title: this.translateService.instant('Persistent Error'),
        text: `${errorMsg}<br><br><b>${this.translateService.instant('Do you wish to continue retrying to connect?')}</b>`,
        noText: this.translateService.instant('Continue'),
        noTimeoutInSec: 60,
        yesText: this.translateService.instant('Access Menu'),
      },
      cssClass: `popover-confirm`,
      backdropDismiss: false,
      showBackdrop: true,
    }))
    .subscribe((confirmPopover: HTMLIonPopoverElement) => {
      from(confirmPopover.onDidDismiss())
      .subscribe((result: OverlayEventDetail<boolean>) => {
        this.isPersistentErrorPopupShowing = false;
        subject.next(result.data);
        subject.complete();
      });

      this.isPersistentErrorPopupShowing = true;
      confirmPopover.present();
    });

    return subject.asObservable();
  }

}
