import { Component, EventEmitter, Injector, Input, NgZone, OnDestroy, Output, ViewChild } from '@angular/core';
import { BarcodeScanResult, BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import * as CortexDecoder from 'cordova-plugin-cortexdecoder';
import { Observable, Subject, Subscription, from, of } from 'rxjs';
import { ConfirmPopover } from 'src/app/popovers';
import { BarcodeScannerLivestreamOverlayComponent } from 'src/app/shared/components/barcode-scanner';
import { DeviceLicense } from 'src/app/shared/models/device-license.enum';
import { RuntimeLayoutHead } from 'src/app/shared/models/runtime-layout/head';
import { RuntimeLayoutDeviceLicense } from 'src/app/shared/models/runtime-layout/runtime-layout-device-license.model';
import { AppService, GeolocationService, TranslateService } from 'src/app/shared/services/app';
import * as Tesseract from 'tesseract.js';
import { DictString, EventObject, Notification, RuntimeLayout, RuntimeLayoutControl, RuntimeLayoutControlCode, RuntimeLayoutEventContext, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutNotifyType, RuntimeLayoutScreen, RuntimeLayoutValue, RuntimeLayoutValueType, Scan, SolutionDeviceControlScannerEnabledFlagType } from '../../../models';
import { BusyService, LocalSettingsService, NotificationService, ScannerService, TextService, VibrationService } from '../../../services';
import { BasePlugin, PluginService, PluginType } from '../../../services/plugin';
import { BrowserUtils, LogUtils } from '../../../utils';
import { BaseComponent } from '../../base/base.component';

@Component({
  template: '<div></div>'
})
export abstract class ControlBaseComponent extends BaseComponent implements OnDestroy {

  @ViewChild(BarcodeScannerLivestreamOverlayComponent) barcodeScannerOverlay: BarcodeScannerLivestreamOverlayComponent;

  @Input() layout: RuntimeLayout;
  @Input() layoutScreen: RuntimeLayoutScreen;

  @Input() set layoutControl(value: RuntimeLayoutControl | RuntimeLayoutHead) {
    this._layoutControl = value;

    if (this._layoutControl?.parseRV('EventGps', false) || (this._layoutControl as RuntimeLayoutControl)?.layoutControlCode === RuntimeLayoutControlCode.LocationControl1) {
      this.geolocationService.start();
    } else {
      this.geolocationService.stop(true);
    }
    this.appService.refreshScannerPlugins();
  };
  get layoutControl(): RuntimeLayoutControl | RuntimeLayoutHead {
    return this._layoutControl;
  }
  private _layoutControl: RuntimeLayoutControl | RuntimeLayoutHead;

  @Input() set staticControl(value: any) {
    this._staticControl = value;

    this.appService.refreshScannerPlugins();
  };
  get staticControl(): any {
    return this._staticControl;
  }
  private _staticControl: any;

  get control(): any {
    return this.staticControl || this.layoutControl || {};
  }

  @Input() isScannerEmulator: boolean;
  @Output() layoutScreenChange = new EventEmitter<RuntimeLayoutScreen>();
  @Output() triggerEvent = new EventEmitter<EventObject>();

  scanValue: string;

  protected appService: AppService;
  private barcodeScanner: BarcodeScanner;
  protected busyService: BusyService;
  protected camera: Camera;
  protected geolocationService: GeolocationService;
  protected localSettingsService: LocalSettingsService;
  protected ngZone: NgZone;
  protected notificationService: NotificationService;
  protected pluginService: PluginService;
  protected popoverCtrl: PopoverController;
  protected scannerService: ScannerService;
  private textService: TextService;
  protected translateService: TranslateService;
  protected vibrationService: VibrationService;

  private initializingScannerPlugins: boolean;
  private scannerSubscription: Subscription;

  constructor(
    protected injector: Injector,
  ) {
    super();

    this.appService = this.injector.get(AppService);
    this.barcodeScanner = this.injector.get(BarcodeScanner);
    this.busyService = this.injector.get(BusyService);
    this.camera = this.injector.get(Camera);
    this.geolocationService = this.injector.get(GeolocationService);
    this.localSettingsService = this.injector.get(LocalSettingsService);
    this.ngZone = this.injector.get(NgZone);
    this.notificationService = this.injector.get(NotificationService);
    this.pluginService = this.injector.get(PluginService);
    this.popoverCtrl = this.injector.get(PopoverController);
    this.scannerService = this.injector.get(ScannerService);
    this.textService = this.injector.get(TextService);
    this.translateService = this.injector.get(TranslateService);
    this.vibrationService = this.injector.get(VibrationService);

    this.subscriptions.push(
      this.appService.listenToRefreshScannerPlugins()
      .subscribe((skipConsumingScans?: boolean) => {
        setTimeout(() => {
          this.refreshScannerPlugins(skipConsumingScans);
        }, 50);
      })
    )
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.unsubscribeScannerSubscription();

    if (!this.control.scannerEnabledType) return;

    this.control.scannerEnabledType = 0;
    this.initPlugins();
  }

  refresh() {

  }

  private unsubscribeScannerSubscription() {
    if (!this.scannerSubscription) return;

    this.scannerSubscription.unsubscribe();
    this.scannerSubscription = null;
  }

  protected isActiveControl() {
    return !this.isScannerEmulator &&
      (this.staticControl?.scannerEnabledType ||
      (
        this.layoutScreen &&
        this.layoutControl &&
        this.layoutScreen.primaryLayoutControlObjectId === this.layoutControl.objectId &&
        !this.scannerService.ignoreScanInPrimaryLayoutControl
      ));
  }

  private refreshScannerPlugins(skipConsumingScans?: boolean) {
    if (
      this.isActiveControl() &&
      (!skipConsumingScans || !this.scannerSubscription)
    ) {
      this.consumePendingScanOrListenForNewOnes();
    }

    if (
      BrowserUtils.isDeviceApp() &&
      this.isActiveControl() &&
      !this.initializingScannerPlugins
    ) {
      this.initializingScannerPlugins = true;
      this.initPlugins();
      this.initializingScannerPlugins = false;
      return;
    }

    const satoPlugin = this.pluginService.getInstance(PluginType.Sato);
    if(satoPlugin.isPluginAllowed()) {
      if (!this.control.scannerEnabledType) {
        satoPlugin.action({ command: 'disable_scanner' });
      } else {
        satoPlugin.action({ command: 'enable_scanner' });
        satoPlugin.start();
      }
    }
  }

  private consumePendingScanOrListenForNewOnes(): void {
    this.unsubscribeScannerSubscription();

    this.ngZone.run(() => {
      const pendingScan = this.scannerService.getPendingScan();
      const scannerEnabledType = (this.staticControl || this.layoutControl || {}).scannerEnabledType;

      if (scannerEnabledType) {
        if (pendingScan) {
          this.invokeCorrespondingScannerCallback(pendingScan);
        } else {
          this.scannerSubscription = this.scannerService.listen()
          .subscribe((scan: Scan) => {
            this.unsubscribeScannerSubscription();

            this.invokeCorrespondingScannerCallback(scan);

            if (
              this.layoutControl?.parseRV('InputBuffering') ||
              (this.layoutControl as RuntimeLayoutControl)?.layoutControlCode === RuntimeLayoutControlCode.QuantityList1
            ) {
              this.appService.refreshScannerPlugins();
            }
          });
        }
      } else {
        if (pendingScan) {
          this.triggerUnhandledScanNotification(pendingScan.value);
          this.scannerService.clear();
        } else {
          this.scannerSubscription = this.scannerService.listen()
          .subscribe((scan: Scan) => {
            this.unsubscribeScannerSubscription();

            this.triggerUnhandledScanNotification(scan.value);
          });
        }
      }
    });
  }

  private invokeCorrespondingScannerCallback(scan: Scan) {
    if (!this.isActiveControl()) return;

    const scannerEnabledType = this.control.scannerEnabledType;
    if (
      (scannerEnabledType & SolutionDeviceControlScannerEnabledFlagType.Simple) === SolutionDeviceControlScannerEnabledFlagType.Simple ||
      scannerEnabledType === SolutionDeviceControlScannerEnabledFlagType.LegacySimpleBarcode // Legacy
    ) {
      this.simpleCallback(scan.value || scan.tagContent);
    } else if (
      (scannerEnabledType & SolutionDeviceControlScannerEnabledFlagType.Advanced) === SolutionDeviceControlScannerEnabledFlagType.Advanced ||
      scannerEnabledType === SolutionDeviceControlScannerEnabledFlagType.LegacyAdvancedBarcode // Legacy
    ) {
      this.advanceCallback(scan);
    }
  }

  private triggerUnhandledScanNotification(scan: string) {
    if (!this.isActiveControl()) return;

    this.notificationService.showNotification(new Notification({
      type: RuntimeLayoutNotifyType.Alert,
      title: this.translateService.instant('Notification'),
      text: this.translateService.instant(`Scan '{scan}' was unhandled by the application.`).replace(/{scan}/g, scan),
    }));
  }

  private initPlugins() {
    const btPlugin = this.pluginService.getInstance(PluginType.Bluetooth);
    const honeywellPlugin = this.pluginService.getInstance(PluginType.Honeywell);
    const nfcPlugin = this.pluginService.getInstance(PluginType.NFC);
    const pointMobilePlugin = this.pluginService.getInstance(PluginType.PointMobile);
    const zebraPlugin = this.pluginService.getInstance(PluginType.Zebra);
    const satoPlugin = this.pluginService.getInstance(PluginType.Sato);

    if (!this.control.scannerEnabledType) {
      if (btPlugin.isPluginAllowed()) {
        btPlugin.stop();
      }

      // if scannerEnabledType not set or NotActive = 0, disable any active scanners
      if (honeywellPlugin.isPluginAllowed()) {
        honeywellPlugin.action({ command: 'disable_scanner' });
      }

      if (pointMobilePlugin.isPluginAllowed()) {
        pointMobilePlugin.action({ command: 'disable_scanner' });
      }

      if (satoPlugin.isPluginAllowed() ) {
        satoPlugin.action({ command: 'disable_scanner' });
      }

      if (zebraPlugin.isPluginAllowed()) {
        zebraPlugin.action({ command: 'disable_scanner' });
      }
    } else {
      // else, check scannerEnableType before enabling specific scanners
      if (
        this.isScannerTypeActive(SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner) &&
        honeywellPlugin.isPluginAllowed()
      ) {
        honeywellPlugin.action({ command: 'enable_scanner' });
        honeywellPlugin.start();
      }

      if (
        this.isScannerTypeActive(SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner) &&
        pointMobilePlugin.isPluginAllowed()
      ) {
        pointMobilePlugin.action({ command: 'enable_scanner' });
        pointMobilePlugin.start();
      }

      if (
        this.isScannerTypeActive(SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner) &&
        zebraPlugin.isPluginAllowed()
      ) {
        zebraPlugin.action({ command: 'enable_scanner' });
        zebraPlugin.start();
      }

      if (this.isScannerTypeActive(SolutionDeviceControlScannerEnabledFlagType.BluetoothScanner)) {
        btPlugin.start();
      }

      if (
        this.isScannerTypeActive(SolutionDeviceControlScannerEnabledFlagType.BuiltInNFC) &&
        nfcPlugin.isPluginAllowed()
      ) {
        nfcPlugin.start();
      }

      if (satoPlugin.isPluginAllowed()) {
        satoPlugin.action({ command: 'enable_scanner' });
        satoPlugin.start();
      }
    }
  }

  private isScannerTypeActive(scannerEnabledType: SolutionDeviceControlScannerEnabledFlagType): boolean {
    return !this.control.scannerEnabledType ||
    (this.control.scannerEnabledType < 4 && scannerEnabledType !== SolutionDeviceControlScannerEnabledFlagType.BuiltInNFC) || // Legacy
    (this.control.scannerEnabledType & scannerEnabledType) === scannerEnabledType;
  }

  protected simpleCallback(scanValue: string, plugin?: BasePlugin) {
    if (plugin) plugin.stop();

    LogUtils.log('scanner.simpleCallback()', scanValue);

    this.ngZone.run(() => {
      this.scanValue = scanValue;

      this.triggerEvent.emit({
        platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton,
      });
    });
  }

  protected advanceCallback(scan: Scan, plugin?: BasePlugin) {
    if (plugin) plugin.stop();

    const eventContextValues = {};
    if (scan.source) {
      eventContextValues['ScanSource'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(scan.source),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }
    if (scan.value) {
      eventContextValues['ScanValue'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(scan.value),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }
    if (scan.valueType) {
      eventContextValues['ScanValueType'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(scan.valueType),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }
    if (scan.tagId) {
      eventContextValues['TagId'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(scan.tagId),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }
    if (scan.tagContent) {
      eventContextValues['TagContent'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(scan.tagContent),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    LogUtils.log('scanner.advanceCallback():', eventContextValues);

    this.ngZone.run(() => {
      if (this.layoutControl?.parseRV('InputBuffering')) {
        this.scanValue = scan.value;
      }

      this.triggerEvent.emit({
        eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
        platformObjectType: RuntimeLayoutEventPlatformObjectType.Scanner,
      });
    });
  }

  protected toggleSoftScan() {
    if (!this.control.scannerEnabledType) return;

    const honeywellPlugin = this.pluginService.getInstance(PluginType.Honeywell);
    const zebraPlugin = this.pluginService.getInstance(PluginType.Zebra);
    const allowedScannerPlugin = honeywellPlugin.isPluginAllowed() ? honeywellPlugin : zebraPlugin.isPluginAllowed() ? zebraPlugin : null;
    if (
      this.isScannerTypeActive(SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner) &&
      allowedScannerPlugin
    ) {
      allowedScannerPlugin.action({ command: 'has_scanner' })
      .subscribe((hasScanner: boolean) => {
        if (hasScanner) {
          allowedScannerPlugin.action({ command: 'toggle_soft_scan' });
        } else {
          this.showCameraBarcodeScanner();
        }
      })
    } else if (this.isScannerTypeActive(SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner)) {
      this.showCameraBarcodeScanner();
    }/* else if (this.isScannerTypeActive(SolutionDeviceControlScannerTypes.BuiltInCameraOcrScanner)) {
      this.showCameraOcrScanner();
    }*/
  }

  private showCameraBarcodeScanner(): void {
    if (BrowserUtils.isDeviceApp()) {
      const cortexLicense = (this.layout?.deviceLicenses || []).find((x: RuntimeLayoutDeviceLicense) => {
        return x.deviceLicenseGuidId === DeviceLicense.CortexNativeEL1 ||
          x.deviceLicenseGuidId === DeviceLicense.CortexNativeEL2 ||
          x.deviceLicenseGuidId === DeviceLicense.CortexWebEL1 ||
          x.deviceLicenseGuidId === DeviceLicense.CortexWebEL2
      });
      if (cortexLicense) {
        CortexDecoder.scan(
          {
            customerID: JSON.parse(cortexLicense.settings || '{}')?.customerID,
            decoderTimeLimit: 50, // default on the web SDK...not really sure on native
            licenseKey: cortexLicense.licenseKey,
            numberOfBarcodesToDecode: 20, // default is 1, max is 20
            exactlyNBarcodes: false,
          },
          (result: any) => {
            if (result?.length) {
              this.onCameraScan(result);
            }
          },
          (error: any) => {
            LogUtils.error('CortexDecoder.scan():', error);
          },
        );
      } else {
        from(this.barcodeScanner.scan({
          showTorchButton: true,
        }))
        .subscribe((result: BarcodeScanResult) => {
          if (!result.cancelled) {
            this.onCameraScan(result);
          }
        }, (error: any) => {
            LogUtils.error('barcodeScanner.scan():', error);
        });
      }
    } else if (this.barcodeScannerOverlay) {
      this.barcodeScannerOverlay.show();
    }
  }

  // private showCameraOcrScanner(): void {
  //   const options: CameraOptions = {
  //     quality: 80,
  //     cameraDirection: this.camera.Direction.BACK,
  //     destinationType: this.camera.DestinationType.DATA_URL,
  //     encodingType: this.camera.EncodingType.JPEG,
  //     mediaType: this.camera.MediaType.PICTURE, // only used if sourceType = PHOTOLIBRARY
  //     sourceType: this.camera.PictureSourceType.CAMERA,
  //     targetWidth: BrowserUtils.isDeviceApp() ? 1024 : window.innerWidth,
  //     targetHeight: BrowserUtils.isDeviceApp() ? 768 : window.innerHeight,
  //     saveToPhotoAlbum: false,
  //     allowEdit: true,
  //     correctOrientation: true,
  //   };

  //   from(this.camera.getPicture(options))
  //   .subscribe((b64: string) => {
  //     const b64Image = 'data:image/jpeg;base64,' + b64;
  //     this.ocrImage(b64Image);
  //   });
  // }

  private ocrImage(b64Image: string) {
    this.busyService.setBusy(true);
    Tesseract.recognize(b64Image)
    .progress((message: Tesseract.Progress) => {
      LogUtils.log('Tesseract.progress()', message);
      if (message.status === 'loading tesseract core') {
        this.busyService.setBusy(true, 'Loading OCR library...this may take a while.');
        LogUtils.log(`Loading OCR library...this may take a while.`);
      } else if (message.status === 'recognizing text') {
        this.busyService.setBusy(true, 'Recognizing text...');
        LogUtils.log(`OCR progress: ${~~(message.progress * 100)}%`);
      }
    })
    .catch((error: Error) => {
      LogUtils.error('Tesseract.recognize():', error);
    })
    .then((result: Tesseract.Page) => {
      this.showConfirmPopover(result.text)
      .subscribe((ok: boolean) => {
        if (ok) {
          this.onCameraScan(result);
        }
      });
    })
    .finally((resultOrError: any) => {
      this.busyService.setBusy(false);
    });
  }

  onCameraScan(cameraScan: any | any[]) {
    // LogUtils.log('onCameraScan: ', cameraScan);
    if (this.barcodeScannerOverlay) {
      this.barcodeScannerOverlay.hide();
    }

    cameraScan = Array.isArray(cameraScan) ? cameraScan : [cameraScan];
    for (const scan of cameraScan || []) {
      this.scannerService.emitScan({
        source: 'CAMERA SCANNER',
        value: scan.code || scan.barcodeData || scan.text,
        valueType: scan.format || scan.symbologyName,
      });
    }

    if (this.isScannerEmulator) {
      // this will just cause the drawer to close itself
      this.triggerEvent.emit({
        platformObjectType: RuntimeLayoutEventPlatformObjectType.Scanner,
      });
    }
  }

  protected disableScannerPlugins() {
    setTimeout(() => { // give it a wee bit of time as the initScanner may still be running
      const btPlugin = this.pluginService.getInstance(PluginType.Bluetooth);
      const honeywellPlugin = this.pluginService.getInstance(PluginType.Honeywell);
      const nfcPlugin = this.pluginService.getInstance(PluginType.NFC);
      const pointMobilePlugin = this.pluginService.getInstance(PluginType.PointMobile);
      const zebraPlugin = this.pluginService.getInstance(PluginType.Zebra);
      const satoPlugin = this.pluginService.getInstance(PluginType.Sato);
      btPlugin.stop();
      honeywellPlugin.stop();
      honeywellPlugin.action({ command: 'disable_scanner' });
      nfcPlugin.stop();
      pointMobilePlugin.stop();
      pointMobilePlugin.action({ command: 'disable_scanner' });
      zebraPlugin.stop();
      zebraPlugin.action({ command: 'disable_scanner' });
      satoPlugin.stop();
      satoPlugin.action({ command: 'disable_scanner' });
    }, 100);
  }

  private showConfirmPopover(text: string): Observable<boolean> {
    const subject = new Subject<boolean>();

    from(this.popoverCtrl.create({
      component: ConfirmPopover,
      componentProps: {
        title: this.textService.instantTranslation('Confirm?'),
        text: text,
      },
      cssClass: `popover-confirm`,
      backdropDismiss: false,
      showBackdrop: true,
    }))
    .subscribe((confirmPopover: HTMLIonPopoverElement) => {
      from(confirmPopover.onDidDismiss())
      .subscribe((result: OverlayEventDetail<boolean>) => {
        subject.next(result.data);
        subject.complete();
      });
      confirmPopover.present();
    });

    return subject.asObservable();
  }

  backButtonOverride(): boolean {
    return false;
  }

  forwardButtonOverride(): boolean {
    return false;
  }

  abstract getControlContext(): DictString<RuntimeLayoutValue>;

  preActionTrigger(): Observable<void> {
    return of(null);
  }

}
